import { useEffect } from 'react';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { Route, Routes, useLocation } from 'react-router-dom';
import { recordPageView, setPageData, setCustomData } from '@thestudentroom/datalayer';
import { initDatadog } from './helpers/Datadog';
import { useSelector, useDispatch } from 'react-redux';
import { GET_USER_SEGMENTS } from './api/User/graph';
import { RootState } from './client';
import { runtimeConfig } from './runtimeConfig';
import { setUserSegments } from './store/user/actions';
import { setOwnership } from './store/adverts/actions';
import Main from './Layouts/Main';
import setUserTracking from './helpers/setUserTracking';

const Error = loadable(() => import('./pages/Error'));
const Home = loadable(() => import('./pages/Home'));
const Thread = loadable(() => import('./pages/Thread'));
const Search = loadable(() => import('./pages/Search'));
const SearchDiscussions = loadable(() => import('./pages/Search/Discussions'));
const SearchArticles = loadable(() => import('./pages/Search/Articles'));
const SearchLearningProviders = loadable(() => import('./pages/Search/LearningProviders'));
const SearchCourses = loadable(() => import('./pages/Search/Courses'));
const SearchProvider = loadable(() => import('./providers/Search/SearchProvider'));
const RegisterPage = loadable(() => import('./pages/Accounts/Register'));
const EmailConfirmPage = loadable(() => import('./pages/Accounts/EmailConfirm'));
const LoginPage = loadable(() => import('./pages/Accounts/Login'));
const ProfilePage = loadable(() => import('./pages/Profile'));
const ChatPage = loadable(() => import('./pages/Chat'));
const SettingsEmailPage = loadable(() => import('./pages/Settings/Email'));
const UnsubscribePage = loadable(() => import('./pages/Unsubscribe'));

export function getAvailableRoutes() {
    const location = useLocation();

    let routes = [
        '/',
        '/search',
        '/search/discussions',
        '/search/articles',
        '/search/universities',
        '/search/courses',
        '/chat',
        '/register',
        '/register/confirm',
        '/login',
        '/profile',
        '/settings/email',
        '/unsubscribe'
    ];

    // doing this to load the thread page from scratch if clicking from a search result
    // to stop none react thread member experience users from getting the react thread page
    // TODO: remove once everyone is on the new react thread page
    if (!location.pathname.includes('/search')) {
        routes.push('/showthread.php');
    }

    return routes;
}

/**
 * @function Routes
 * @param children
 * @returns A Switch component, with built in tracking for pageviews. Component listens for a route change and fires off a pageview event to GA
 */
export default function ({
    serverLocation,
    postParams
}: {
    serverLocation?: URL;
    postParams: any;
}) {
    const gb = useGrowthBook();
    const location = useLocation();
    const user = useSelector((state: RootState) => state.user);
    const userType = user.userType;
    const dispatch = useDispatch();
    const datadogMonitoring: boolean = runtimeConfig.datadogSessionMonitor === 'true';

    // Request data for any institution linked to this forum
    const { refetch: refetchSegments } = useGraphQLQuery(GET_USER_SEGMENTS, {
        errorPolicy: 'all',
        ssr: false,
        onCompleted: (data) => {
            // Only put segments into state if we get any back, otherwise it sets them to `null` and breaks things
            if (data.userSegments) {
                dispatch(setUserSegments(data.userSegments));
            }
        },
        // dont batch in case of performance issues
        context: {
            batch: false
        },
        skip: location.pathname.includes('/register/confirm')
    });

    // Only on first page load, send web vitals data to GA and initialise datadog
    useEffect(() => {
        if (userType && datadogMonitoring) {
            initDatadog(userType);
        }
    }, [userType]);

    // When the URL changes, send pageview data to GA
    useEffect(() => {
        // dont send double pageviews
        if (user.loading) {
            return;
        }

        if (!location.pathname.includes('/register/confirm')) {
            // Refetch segments
            refetchSegments();
        }

        setUserTracking(gb, user);

        setCustomData({
            pageTechnology: 'react'
        });

        // Reset ownership state if not on a discussion page
        if (!location.pathname.includes('showthread.php')) {
            dispatch(setOwnership(null));
        }

        // Make sure we reset the page type to be recalculated
        setPageData({
            pageType: undefined,
        });

        // Allows page to load before firing event
        setTimeout(() => {
            recordPageView();
        }, 1000);
    }, [location, user.loading]);

    return (
        <Routes>
            <Route path={'/'} element={<Main />}>
                {/* Home */}
                <Route key={'home-page'} path={'/'} element={<Home />} />
                {/* Threads */}
                <Route key={'thread-page'} path={'/showthread.php'} element={<Thread />} />
                {/* Search */}
                <Route
                    key={'search'}
                    path="/search"
                    element={
                        <SearchProvider serverLocation={serverLocation}>
                            <Search />
                        </SearchProvider>
                    }
                >
                    <Route key={'search-home'} path={''} element={<SearchDiscussions />} />
                    <Route
                        key={'search-discussions'}
                        path={'discussions'}
                        element={<SearchDiscussions />}
                    />
                    <Route key={'search-articles'} path={'articles'} element={<SearchArticles />} />
                    <Route
                        key={'search-learningproviders'}
                        path={'universities'}
                        element={<SearchLearningProviders />}
                    />
                    <Route key={'search-courses'} path={'courses'} element={<SearchCourses />} />
                </Route>
                <Route key={'chat-page'} path={'/chat'} element={<ChatPage />} />

                {/* Settings */}
                <Route
                    key={'settings-email-page'}
                    path={'/settings/email'}
                    element={<SettingsEmailPage serverLocation={serverLocation} />}
                />
            </Route>
            {/* Accounts */}
            <Route
                key={'register-page'}
                path={'/register'}
                element={
                    <RegisterPage
                        googleClientId={runtimeConfig.googleClientId}
                        // facebookClientId={runtimeConfig.facebookClientId}
                        appleClientId={runtimeConfig.appleClientId}
                        requestParams={postParams}
                        serverLocation={serverLocation}
                    />
                }
            />
            <Route
                key={'email-confirm-page'}
                path={'/register/confirm'}
                element={<EmailConfirmPage serverLocation={serverLocation} />}
            />
            <Route
                key={'login-page'}
                path={'/login'}
                element={
                    <LoginPage
                        googleClientId={runtimeConfig.googleClientId}
                        // facebookClientId={runtimeConfig.facebookClientId}
                        appleClientId={runtimeConfig.appleClientId}
                        requestParams={postParams}
                    />
                }
            />
            <Route
                key={'profile-page'}
                path={'/profile/:question?'}
                element={<ProfilePage serverLocation={serverLocation} />}
            />
            <Route key={'unsubscribe-page'} path={'/unsubscribe'} element={<UnsubscribePage />} />
            <Route
                key={'notfound-page'}
                path="*"
                element={<Error style={{ width: '100%' }} errorType={'notfound'} />}
            />
        </Routes>
    );
}
